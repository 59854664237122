import React from "react";
import { Layout } from "../components/Layout";
import barnPic from "../images/barnpic.jpeg";

const APreacherAndHisBarnsPage = () => {
  return (
    <Layout>
      <main className="relative bg-white z-0">
        <img
          src={barnPic}
          alt=""
          className="w-full h-[36rem] md:h-[48rem] object-cover z-0 absolute"
        />
        <div className="w-full h-[36rem] md:h-[48rem] z-10 bg-gray-800 absolute mix-blend-multiply opacity-60" />
        <div className="w-full h-[36rem] md:h-[48rem] z-20 relative">
          <div className="absolute w-full pt-28 px-16 flex flex-col items-center justify-center">
            <h1 className="text-white mt-8 md:mt-64 text-5xl font-serif">
              A Preacher and His Barns
            </h1>
            <h2 className="text-white text-xl md:text-2xl mt-2">
              Founded by Rich Hall,{" "}
              <span className="italic">A Preacher and His Barns</span> is a
              social media devotional blog where Rich shares his love for Christ
              and his love for photographing old barns.
            </h2>
            <div className="flex justify-center mt-8 sm:mt-48 lg:mt-8">
              <a
                href="https://www.facebook.com/barnpics/"
                target="_blank"
                rel="noreferrer"
                className="rounded-full bg-white hover:bg-cyan-900 text-cyan-900 hover:text-white shadow-gray-800 shadow-sm hover:shadow-cyan-900 text-sm sm:text-lg px-8 py-3"
              >
                Visit the Facebook Page
              </a>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default APreacherAndHisBarnsPage;
